import PageComponent from '../component/page-component';


class PreviewsSliderNavigation extends PageComponent {

	constructor({
		root,
		element,
		navigationAttribute = 'goTo',
	}) {
		super({root: root, element: element});
		this.navigationAttribute = navigationAttribute;
	}


	prepare() {
		const id = this.dataAttr().get('for', '');
		this.slider = this.components.queryComponent(this.root, this.dataSelector('id', id));
		if (this.slider) {
			this.listeners.click = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'click', this.onClick.bind(this));
			this.listeners.swipe = this.events.on(this.slider.getElement(), 'swipe', this.onSwipe.bind(this));
			this.listeners.drag = this.events.on(this.slider.getElement(), 'dragstart', this.onDrag.bind(this));
		}
		this.updateButtons();
	}


	onClick(event, target) {
		const direction = this.dataAttr(target).get(this.navigationAttribute);
		this.slider[direction]();
		this.updateButtons();
	}
	onDrag(event) {
		event.preventDefault();
		return false;
	}
	onSwipe(event, target) {
		if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT) {
			this.slider.prev();
		}
		if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_LEFT) {
			this.slider.next();
		}
		window.getSelection().removeAllRanges(); // get rid of selected text
		this.updateButtons();
	}
	updateButtons() {
		const buttons = this.element.querySelectorAll(this.dataSelector(this.navigationAttribute));
		buttons.forEach(button => {
			const dir = this.dataAttr(button).get(this.navigationAttribute);
			if (dir === 'next') this.classList(button).toggle('disabled', !this.slider.hasNext());
			if (dir === 'prev') this.classList(button).toggle('disabled', !this.slider.hasPrev());
		});
	}


}


export default PreviewsSliderNavigation;
