import ExternalVideo from './external-video';
// import Video from './video';
// import Pictures from './pictures';
// import Videos from './videos';
// import VideoFactory from './video-factory';
// import Covers from './covers';
import AsyncPicture from './async-picture';



const config = (di) => {
	di
		.setType({type: ExternalVideo, name: 'ExternalVideo', parent: 'PageComponent'})

		// .setType({type: Video, name: 'Video', mixins: ['updateStatusMixin'], params: {
		// 		events: di.lazyGet('events/events'),
		// 		dataAttr: di.lazyGet('dom/dataAttr'),
		// 		classList: di.lazyGet('dom/classList')
		// 	}
		// })

		// .setType({type: VideoFactory, name: 'VideoFactory', parent: 'Factory'})
		// .set('media/videoFactory', di.lazyNew('VideoFactory'))

		// .setType({type: Videos, name: 'Videos', parent: 'PageComponent', setters: {
		// 		injectVideoFactory: di.lazyGet('media/videoFactory')
		// 	}
		// })

		// .setType({type: Pictures, name: 'Pictures', parent: 'PageComponent'})

		.setType({type: AsyncPicture, name: 'AsyncPicture', parent: 'PageComponent'})

		// .setType({type: Covers, name: 'Covers', parent: 'PageComponent'})
		;
};


export default config;
