import PageComponent from '../../common/component/page-component';

class SectionNavi extends PageComponent {

	constructor({
								root,
								element
							}) {
		super({root: root, element: element});
	}


	prepare() {
		this.currentSection = '';
		const frag = document.createDocumentFragment();

		// pick up anchor links from data attribute out of page content
		// <a id="uniqueId" data-ubf-sectionnavi-label="Section">
		this.sectionAnchors = this.root.querySelectorAll(this.dataSelector('sectionnaviLabel'));

		this.sectionAnchors.forEach(sectionAnchor => {
			const item = document.createRange().createContextualFragment(this.getItem(sectionAnchor.dataset.ubfSectionnaviLabel, sectionAnchor.id));
			frag.appendChild(item);
		});

		// create menu from predefined data attribute
		// ids must be present in content!
		// <div class="ubf-sectionNavi" data-ubf-component="SectionNavi" data-ubf-sectionnavi-menu='[{"anchor":"sectionnavi_cover","label":"Start"},{"anchor":"sectionnavi_news","label":"Aktuelles"}]' />
		this.menuAnchors = [];
		this.sectionMenu = this.dataAttr(this.element).get('sectionnaviMenu');
		this.sectionMenu.forEach(menuItem => {
			const item = document.createRange().createContextualFragment(this.getItem(menuItem.label, menuItem.anchor));
			frag.appendChild(item);
			this.menuAnchors.push(document.getElementById(menuItem.anchor));
		});
		this.element.appendChild(frag);
		this.listeners.scroll = this.events.on(window, 'window:scroll window:resize', this.onScroll.bind(this));
		this.scrollExpandTimeout = null;
	}

	getItem(label, id) {
		return `<span data-ubf-sectionnavi-anchor="${id}" class="ubf-sectionNavi__item">
			<a href="#${id}" class="ubf-sectionNavi__icon"></a>
			<a href="#${id}" class="ubf-sectionNavi__label">${label}</a>
		</span>`;
	}


	onScroll() {
		// expand ui for a short time on scroll
		this.element.classList.add('ubf-js-scrolling');
		clearTimeout(this.scrollExpandTimeout);
		this.scrollExpandTimeout = setTimeout(() => {
			this.element.classList.remove('ubf-js-scrolling');
		}, 1000);

		const items = this.element.querySelectorAll('.ubf-sectionNavi__item');

		const combined = [...Array.from(this.sectionAnchors), ...this.menuAnchors];
		const anchors = combined
			.filter(anchor => (anchor.getBoundingClientRect().top < 1))
			.sort((a1, a2) => (a1.getBoundingClientRect().top > a2.getBoundingClientRect().top?-1:1));

		if (anchors.length > 0) {
			// anchor in range
			const id = anchors[0].id;
			if (this.currentSection !== id) {
				this.currentSection = id;
				items.forEach(item => {
					item.classList.remove('ubf-js-current');
					if (item.dataset.ubfSectionnaviAnchor === id) {
						item.classList.add('ubf-js-current');
					}
				});
			}
		} else {
			// no anchor in range (but remove only once)
			if (this.currentSection !== null) {
				this.currentSection = null;
				items.forEach(item => {
					item.classList.remove('ubf-js-current');
				});
			}
		}
	}
}

export default SectionNavi;
