import PageComponent from '../../common/component/page-component';


// component-wrapper to add other specific components inside the user-generated content blocks,
// where we cannot have control on markup to add the required data-attributes upfront
class UserGeneratedContent extends PageComponent {

	constructor({root, element, componentDefinitions = [
		{name: 'UserGeneratedTable', selector: 'table:not(.responsive)', dataAttributes: {}},
		{name: 'UserGeneratedZoomImage', selector: 'a.ubf-ugc__zoomImage', dataAttributes: {}}
	]}) {
		super({root: root, element: element});
		this.componentDefinitions = componentDefinitions;
	}

	prepare() {
		for (const entry of this.componentDefinitions) {
			const nodes = this.element.querySelectorAll(entry.selector);
			const nodesLen = nodes.length;
			// looping backwards so that sub components are already available when
			// their eventual ancestor component is starting
			for (let i = nodesLen - 1; i >= 0; i--) {
				const node = nodes[i];
				const dataAttr = this.dataAttr(node);
				for (const name in entry.dataAttributes) {
					if (entry.dataAttributes.hasOwnProperty(name)) {
						dataAttr.set(name, entry.dataAttributes[name]);
					}
				}
				dataAttr.set('component', entry.name);
			}
		}
	}

}


export default UserGeneratedContent;
