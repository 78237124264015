import PageComponent from '../../common/component/page-component';
import escape from './../../common/template/escape';



class TemplateBuilder extends PageComponent {

	constructor({
		root,
		element,
		jsonUrl = 'https://uni-bielefeld.hr4you.org/api/jobs/json/set/Website',
		contentBlockAttribute = 'contentBlock',
		employmentTypeIdsAttribute = 'employmentTypeIds'
	}) {
		super({root: root, element: element});
		this.escape = escape;
		this.jsonUrl = jsonUrl;
		this.contentBlockAttribute = contentBlockAttribute;
		this.employmentTypeIdsAttribute = employmentTypeIdsAttribute;

		this.json = {};
		this.jobPrefix = 'job';
		this.employmentTypeIds = 0;
		this.currentLanguage = 'de';
		this.contactPrefix = 'contact';

		this.germanTerms = new Map([
			['jobTitle', 'Stellentitel'],
			['jobCustomer', 'Einrichtung'],
			['jobPublishingDateUntil', 'Bewerbungsfrist'],
			['jobKennziffer', 'Kennziffer'],
			['jobTimeLimitation', 'Befristung'],
			['jobProfessorshipLastName', 'Professor*in'],
			['jobOffer', 'zum Stellenangebot'],
		]);
		this.englishTerms = new Map([
			['jobTitle', 'position'],
			['jobCustomer', 'institution'],
			['jobPublishingDateUntil', 'application deadline'],
			['jobKennziffer', 'job ID'],
			['jobTimeLimitation', 'time limitation'],
			['jobProfessorshipLastName', 'professor'],
			['jobOffer', 'to the job offer'],
		]);
	}


	prepare() {
		this.contentBlock = this.element.querySelector(this.dataSelector(this.contentBlockAttribute));
		this.employmentTypeIds = this.dataAttr(this.element).get(this.employmentTypeIdsAttribute);
		this.getStoredLocale();
		this.fetchJson();
		this.populateTable();
	}


	fetchJson() {
		const http = new XMLHttpRequest();
		http.open('GET', this.jsonUrl, false);
		http.send(null);
		const response = http.responseText;
		this.json = JSON.parse(response);
		http.onerror = (error) => {
			console.error(error);
		};
	}


	createNode(type, classes = [], attributes = [], href = '') {
		const node = document.createElement(type);

		if (classes) {
			for (const nodeClass of classes) {
				node.classList.add(nodeClass);
			}
		}

		if (attributes) {
			for (const attr of attributes) {
				const value = attr.value ? attr.value : '';
				this.dataAttr(node).set(attr.name, value);
			}
		}

		if (type && 'a' && href) {
			node.href = href;
		}

		return node;
	}

	getStoredLocale() {
		if (document.cookie) {
			const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)RoxenConfig\s*=\s*([^;]*).*$)|^.*$/, '$1');
			this.currentLanguage = cookieValue !== '' ? cookieValue : 'de';
		}
	}


	populateTable() {
		if (this.json.HR4YOU_JOBS) {
			const entries = this.json.HR4YOU_JOBS;

			this.fragment = document.createDocumentFragment();
			const applicationTypes = this.groupTypes(entries);

			this.createTable(applicationTypes);

			this.element.appendChild(this.fragment);
		}
	}


	groupTypes(entries) {
		const applicationTypes = new Map();

		for (const entry of entries) {
			const applicationData = {
				items: [],
				hasProfessorship: parseInt(entry.jobEmploymentTypeId, 10) === 6,
				employmentTypeId: parseInt(entry.jobEmploymentTypeId, 10)
			};
			const type = entry.jobEmploymentType;

			if (!applicationTypes.has(type)) {
				applicationData.items.push(entry);
				applicationTypes.set(type, applicationData);
			} else {
				const typeMapEntry = applicationTypes.get(type);
				typeMapEntry.items.push(entry);
				applicationTypes.set(type, typeMapEntry);
			}
		}
		return applicationTypes;
	}


	createTable(types) {
		this.contentBlockCopy = {};
		for (const [type, applicationData] of types) {
			for (const employmentTypeId of this.employmentTypeIds) {
				if (applicationData.employmentTypeId === parseInt(employmentTypeId, 10)) {
					this.contentBlockCopy = this.contentBlock.cloneNode(true);
					this.contentBlockCopy.classList.remove('ubf-contentBlock__template');

					// do not show title
					// const title = this.contentBlockCopy.querySelector(this.dataSelector('applicationTitle'));
					// title.innerHTML = this.escape(type);

					const searchLabel = this.contentBlockCopy.querySelector(this.dataSelector('searchLabel'));
					if (searchLabel) searchLabel.innerHTML = (this.currentLanguage === 'en')?'Search':'Suche';

					this.createTableMarkup(type, applicationData);
					this.fragment.appendChild(this.contentBlockCopy);
				}
			}
		}
	}


	createTableMarkup(type, applicationData) {
		const tableHeadRow = this.contentBlockCopy.querySelector(this.dataSelector('tableHead'));
		const tableBody = this.contentBlockCopy.querySelector(this.dataSelector('tableBody'));
		const applications = applicationData.items;
		const hasProfessorship = applicationData.hasProfessorship;

		for (let i = 0; i < applications.length; i++) {
			const application = applications[i];
			const tr = this.createNode('tr');
			const applicationTerms = this.currentLanguage === 'de' ? this.germanTerms : this.englishTerms;
			for (const [property, term] of applicationTerms) {
				if (property in application) {
					if (property === 'jobPublishingDateUntil') {
						const convertedDate = this.convertDate(application[property]);
						application[property] = convertedDate;
					}

					if (property === 'jobProfessorshipLastName' && hasProfessorship) {
						const lastName = application.jobProfessorshipLastName ? application.jobProfessorshipLastName + ' ' : '';
						const firstName = application.jobProfessorshipFirstName ?  application.jobProfessorshipFirstName + ' ' : '';
						const title = application.jobProfessorshipTitle ? application.jobProfessorshipTitle + ' ': '';
						const fullName = title + firstName + lastName;
						application.jobProfessorshipLastName = fullName;
					}

					if (i === 0) {
						let th = null;
						if ((!hasProfessorship && property !== 'jobProfessorshipLastName') || hasProfessorship ) {
							if (property === 'jobOffer') {
								th = this.createNode('th', ['ubf-ugc__mobileCell']);
							} else {
								th = this.createNode('th');
							}

							th.innerHTML = this.escape(term);
							tableHeadRow.append(th);
						}
					}

					const div = this.createNode('div', [], [{name: 'data-ubf-nested-header'}]);
					let a = null;
					let td = null;
					let p = null;

					if (property === 'jobOffer') {
						a = this.createNode('a', ['ubf-ugc__tableLink', 'ubf-ugc__tableLink--mobile']);
						td = this.createNode('td', ['ubf-ugc__mobileCell'], [{name: 'tableCell'}]);
						p = this.createNode('p', [], []);
					} else {
						a = this.createNode('a', ['ubf-ugc__tableLink']);
						td = this.createNode('td', [], [{name: 'tableCell'}]);
						p = this.createNode('p', [], [{name: 'cellText'}]);
					}


					a.href = application.jobOffer;
					a.setAttribute('target', '_blank');
					const applicationProperty = application[property] ? application[property] : '';
					const escapedProperty = this.escape(applicationProperty);

					if ((!hasProfessorship && property !== 'jobProfessorshipLastName') || hasProfessorship) {
						p.innerHTML = escapedProperty;
						a.append(p);
						a.append(div);
						td.append(a);
						tr.append(td);
					}
				}
			}
			this.dataAttr(tr).set('tableRow', '');
			tableBody.append(tr);
		}
	}

	convertDate(dateString) {
		const date = new Date(dateString);
		const days = ('0' + date.getDate()).slice(-2);
		const months = ('0' + (date.getMonth() + 1)).slice(-2);
		const year = date.getFullYear();
		const convertedDate = days + '.' + months + '.' + year;

		return convertedDate;
	}
}

export default TemplateBuilder;
